export const comp=[
    {heading:"KGV SMART MOTOR   ",
     paragraph:`A KGV SMART MOTOR is an electric motor that is integrated
     into the hub of a vehicle’s wheel which directly drives the
     vehicle wheels. These vehicle hub motors are used in the
     application of E-mobility to drive trains in electric
     vehicles which can give the solution of an easy wheel
     drive system in the 2-wheeler or 4-wheel drive system.`},
     {heading:"KGV E-POWER HOUSE",
     paragraph:`The KGV E-POWER HOUSE is used to control and manage the power
     output through the motor, digital meter, and other
     accessories in hybrid electric vehicles. The KGV E-POWER HOUSE is
     single-phase with a power range of 1-2.5KW, a voltage
     range of 36-72V with a current supply of 20-40A.`},
     {heading:"DUEL FUEL THROTTLE",
     paragraph:`The DUEL FUEL THROTTLE is based on a Hall effect magnetic sensor for
     long operational life for the throttle speed controlled by
     the switches as Low-Medium-High and also for ForwordReverse direction.`},
     {heading:"DC/DC CONVERTER     ",
     paragraph:`A DC/DC converter is an electromechanical device that
     converts the direct current source from one level to
     another level that's high to low or vice versa. These
     DC/DC converters are used in high to low conversion in
     this application for 72/60/48V to 12 Volts`},
     {heading:"HARNESS",
     paragraph:`Wire and accessories contain the wiring, male and female
     connectors, and battery box with other accessories to
     require the battery box fixing`},
    
     
    
];

